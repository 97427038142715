<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">근무일</div>
            <div>
              <DxDateBox
                styling-mode="outlined"
                width="120"
                height="30"
                v-model="searchType.customTypes.dayStart"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.dayEnd"
                maxLength="10"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="기준일은 필수입니다." />
                </DxValidator>
              </DxDateBox>
            </div>
            <div class="mt-1">~</div>
            <div>
              <DxDateBox
                styling-mode="outlined"
                width="120"
                height="30"
                v-model="searchType.customTypes.dayEnd"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                maxLength="10"
                :min="searchType.customTypes.dayStart"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="기준일은 필수입니다." />
                </DxValidator>
              </DxDateBox>
            </div>

            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>

      <div>
        <esp-dx-data-grid :data-grid="dataGrid" ref="personalWorktimeGrid" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { getPastFromToday, isSuccess, convertDateFormat } from '@/plugins/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,

      DxRequiredRule,
      DxValidator,
    },
    data() {
      return {
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(14, 'days'),
            dayEnd: getPastFromToday(-1, 'days'),
          },
          paramsData: null,
        },
        dataGrid: {
          keyExpr: 'attendanceId',
          refName: 'personalWorktimeGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            rowClick: true,
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '결재요청',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 75,
                  height: 30,
                  onClick() {
                    this.onChangeWorkTimeEdit();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '재기안',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  onClick() {
                    this.onReExamination();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '근무일',
              dataField: 'workDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '일반근무유형',
              dataField: 'workBasicNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '일반근무시간',
              dataField: 'workBasicStartTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.workBasicStartTime) {
                  return `${rowData.workBasicStartTime} ~ ${rowData.workBasicEndTime}`;
                }
              },
            },
            {
              caption: '출결상태',
              dataField: 'attendanceState',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                // FIXME : onExporting customizeCell 엑셀 다운로드시 해당 셀의 값 부여 필요.
                let div = document.createElement('div');
                const data = options.data;

                // 정상
                if (data.normalFl === 'Y') {
                  let span = document.createElement('span');
                  span.className =
                    'bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 ' +
                    'dark:text-green-400 border border-green-400 ml-1 cursor-pointer';
                  span.innerText = '정상';
                  // span.addEventListener('click', () => {
                  // 	alert('출결상태변경 팝업 호출');
                  // });
                  div.appendChild(span);
                }

                // 지각
                if (data.tardinessFl === 'Y') {
                  let span = document.createElement('span');
                  span.className =
                    'bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 ' +
                    'rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300 ml-1 cursor-pointer';
                  span.innerText = '지각';
                  // span.addEventListener('click', () => {
                  // 	alert('출결상태변경 팝업 호출');
                  // });
                  div.appendChild(span);
                }

                // 결근
                if (data.absentFl === 'Y') {
                  let span = document.createElement('span');
                  span.className =
                    'bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 ' +
                    'rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 ml-1 cursor-pointer';
                  span.innerText = '결근';
                  // span.addEventListener('click', () => {
                  // 	alert('출결상태변경 팝업 호출');
                  // });
                  div.appendChild(span);
                }

                // 조퇴
                if (data.dayoffFl === 'Y') {
                  let span = document.createElement('span');
                  span.className =
                    'bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 ' +
                    'rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400 ml-1 cursor-pointer';
                  span.innerText = '조퇴';
                  // span.addEventListener('click', () => {
                  // 	alert('출결상태변경 팝업 호출');
                  // });
                  div.appendChild(span);
                }
                container.append(div);
              },
            },
            {
              caption: '실제근무시간',
              dataField: 'firstLoginDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.firstLoginDt) {
                  return `${convertDateFormat(rowData.firstLoginDt, 'YYYY-MM-DD HH:mm:ss')} ~ ${convertDateFormat(rowData.lastLogoutDt, 'YYYY-MM-DD HH:mm:ss')}`;
                }
              },
            },
            {
              caption: '변경근무시간',
              dataField: 'loginTimeEdit',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.loginTimeEdit) {
                  return `${convertDateFormat(rowData.loginTimeEdit, 'YYYY-MM-DD HH:mm:ss')} ~ ${convertDateFormat(rowData.logoutTimeEdit, 'YYYY-MM-DD HH:mm:ss')}`;
                }
              },
            },
            {
              caption: '최종승인여부',
              dataField: 'finalApprovalStatus',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.approvalStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 결재요청 */
      onChangeWorkTimeEdit() {
        const selectedRowsData = this.$refs.personalWorktimeGrid.getGridInstance.getSelectedRowsData();
        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        if (selectedRowsData[0].approvalId) {
          let params = { updateYn: true, reSubmission: true, formData: selectedRowsData[0] };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/worktime-edit/worktime-detail' });
        } else {
          let params = { updateYn: false, reSubmission: true, formData: selectedRowsData[0] };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/worktime-edit/worktime-detail' });
        }
      },
      /** @description : 재기안 */
      onReExamination() {
        const selectedRowsData = this.$refs.personalWorktimeGrid.getGridInstance.getSelectedRowsData();
        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        if (
          selectedRowsData[0].finalApprovalStatus === 'APPROVAL_CANCEL' ||
          selectedRowsData[0].finalApprovalStatus === 'REJECTED' ||
          selectedRowsData[0].finalApprovalStatus === 'REVOKED' ||
          selectedRowsData[0].finalApprovalStatus === 'PENDING'
        ) {
          let params = { updateYn: true, reSubmission: false, formData: selectedRowsData[0] };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/worktime-edit/worktime-detail' });
        } else {
          this.$_Msg('대기, 반려, 취소일 경우에만 재기안이 가능합니다.');
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        const payload = {
          actionname: 'EWM_PERSONAL_ATTENDANCE',
          data: {
            ymd: `${this.searchType.customTypes.dayStart}~${this.searchType.customTypes.dayEnd}`,
            loginId: this.$store.getters.getLoginId,
          },
          useErrorPopup: true,
        };

        let rtnData = [];
        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          rtnData = res.data.data;
        }
        this.dataGrid.dataSource = rtnData;
      },
    },
    async mounted() {
      await this.selectDataList();
    },
  };
</script>
